@use 'sass:map';
@use '../../../styles/@tokens' as tokens;

.Counter {
  display: inline-block;
  padding-inline: 1.5 * tokens.$space-300;
  margin-left: tokens.$space-400;
  font-weight: map.get(tokens.$body-xsmall-text-regular, 'mobile', 'font-weight');
  font-size: map.get(tokens.$body-xsmall-text-regular, 'mobile', 'font-size');
  line-height: 1.5;
  color: tokens.$text-primary-inverted-default;
  border-radius: 1.875em;
  background-color: tokens.$action-primary-default;
}

.Counter--disabled {
  color: tokens.$action-secondary-disabled;
  background-color: tokens.$text-primary-disabled;
}
