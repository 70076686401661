@use '../../../styles/@tokens' as tokens;
@use "../../../node_modules/@lmc-eu/spirit-web/scss/tools/breakpoint";

$homepage-container-max-width: 1006px;

.HomepageContainer {
  padding-inline: tokens.$space-700;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    padding-inline: tokens.$space-800;
  }

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    padding-inline: #{(tokens.$container-max-width - $homepage-container-max-width) / 2};
  }
}