@use 'sass:map';
@use '../../../styles/@tokens' as tokens;
@use '../../../styles/tokens/colors' as colors;

.LanguageSwitcher__linkWithFlag {
  display: inline-flex;
  align-items: center;
  line-height: 1;

  &:not(:last-of-type) {
    margin-bottom: tokens.$space-500;
  }
}

.LanguageSwitcher__linkWithFlag > svg {
  flex-shrink: 0;
  margin-left: tokens.$space-600;
}

.LanguageSwitcher a {
  color: colors.$givry-300;

  &:focus,
  &:hover {
    color: colors.$givry-400;
  }

  &:active {
    color: colors.$givry-500;
  }

  &:disabled {
    color: colors.$gray-500;
  }
}
