@use 'styles/@tokens' as tokens;
@use 'styles/tokens/colors' as colors;
@use '@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;
@use "sass:map";

$company-select-width: 340px;
$profile-logo-max-width: 72px;
$profile-logo-height: 24px;

.B2bAdminNavbar {
  font-size: map.get(tokens.$body-small-text-regular, 'mobile', 'font-size');
  border-bottom: tokens.$border-width-100 colors.$border-primary-default tokens.$border-style-100;
}

.B2bAdminNavbar__menuItem {
  gap: tokens.$space-400;
  padding: tokens.$space-300 tokens.$space-400;
  font-weight: map.get(tokens.$body-medium-text-regular, 'mobile', 'font-weight');
  font-size: map.get(tokens.$body-medium-text-regular, 'mobile', 'font-size');
  line-height: map.get(tokens.$body-medium-text-regular, 'mobile', 'line-height');
  color: tokens.$text-primary-default;
  border: none;
  background-color: colors.$background-basic;

  &:focus,
  &:hover,
  &:active {
    color: tokens.$text-primary-default;
    background-color: tokens.$background-cover;
  }

  &:disabled {
    color: colors.$action-link-primary-disabled;
  }
}

.B2bAdminNavbar__menuItem--active {
  font-weight: map.get(tokens.$body-medium-text-bold, 'mobile', 'font-weight');
  background-color: tokens.$background-cover;
}

.B2bAdminNavbar__contentWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: tokens.$space-500 tokens.$space-0;
}

.B2bAdminNavbar__leftContent {
  display: flex;
  gap: tokens.$space-900;
  align-items: center;
}

.B2bAdminNavbar__leftContentText {
  display: flex;
  gap: tokens.$space-400;
}

.B2bAdminNavbar__rightContent {
  display: flex;
  gap: tokens.$space-600;
  align-items: center;
  justify-content: flex-end;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    flex-grow: 1;
  }
}

.B2bAdminNavbar__companySelect {
  width: $company-select-width;
}

.B2bAdminNavbar__companySelectWrapper {
  display: none;

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    display: flex;
    gap: tokens.$space-600;
    align-items: center;
  }
}

.B2bAdminNavbar__profileLogo {
  position: relative;
  display: flex;
  max-width: $profile-logo-max-width;
  height: $profile-logo-height;
  overflow: hidden;
}

.B2bAdminNavbar__profileLogo > img {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
  object-position: right center;
}

.B2bAdminNavbar__atmoskopLogo {
  display: flex;
  flex-direction: column;
}

.B2bAdminNavbar__freeProfileBadge {
  align-self: start;
  padding-inline: tokens.$space-300;
  font-weight: map.get(tokens.$body-xsmall-text-bold, 'mobile', 'font-weight');
  font-size: 10px;
  text-transform: uppercase;
  color: tokens.$text-primary-inverted-default;
  border-radius: tokens.$radius-100;
  background: colors.$brand-primary;
}
