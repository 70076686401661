@use 'sass:map';
@use '../../styles/@tokens' as tokens;
@use 'theme' as theme;

.Footer {
  padding: tokens.$space-1200 tokens.$space-600;
  font-size: map.get(tokens.$body-small-text-regular, 'mobile', 'font-size');
  color: tokens.$text-secondary-inverted-default;
  background-color: tokens.$background-cover-inverted;
}

.Footer__title {
  margin-bottom: tokens.$space-600;
  font-weight: map.get(tokens.$body-medium-text-regular, 'mobile', 'font-weight');
  font-size: map.get(tokens.$body-medium-text-regular, 'mobile', 'font-size');
  color: theme.$footer-title-color;
}

.Footer__container {
  max-width: 40rem;
  margin: 0 auto tokens.$space-900;
}

.Footer a {
  color: theme.$footer-link-default;

  &:focus,
  &:hover {
    color: theme.$footer-link-hover;
  }

  &:active {
    color: theme.$footer-link-active;
  }

  &:disabled {
    color: theme.$footer-link-disabled;
  }
}

.Footer ul {
  list-style: none;
}
