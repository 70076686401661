@use '../../../styles/@tokens' as tokens;
@use '@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;
@use "sass:map";

.HomepageYouCanDo__item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: tokens.$space-900 tokens.$space-0 tokens.$space-900;

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: tokens.$space-1300 tokens.$space-0 tokens.$space-1300;
  }
}

.HomepageYouCanDo__item:first-of-type {
  padding-top: tokens.$space-1200;
}

.HomepageYouCanDo__item:last-of-type {
  padding-bottom: tokens.$space-0;
}

.HomepageYouCanDo__item:nth-child(even) {
  @include breakpoint.up(tokens.$breakpoint-desktop) {
    flex-direction: row-reverse;
  }
}

.HomepageYouCanDo__itemBody {
  padding-top: tokens.$space-800;

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    flex: 3 1 0;
    padding-top: tokens.$space-0;
    padding-left: tokens.$space-900;
  }
}

.HomepageYouCanDo__item:nth-child(even) .HomepageYouCanDo__itemBody {
  @include breakpoint.up(tokens.$breakpoint-desktop) {
    padding-right: tokens.$space-900;
    padding-left: tokens.$space-0;
    text-align: right;
  }
}

.HomepageYouCanDo__heading {
  font-weight: map.get(tokens.$heading-medium-text, 'tablet', 'font-weight');
  font-size: map.get(tokens.$heading-medium-text, 'tablet', 'font-size');
}

.HomepageYouCanDo__itemBody h3 {
  font-weight: map.get(tokens.$heading-large-text, 'mobile', 'font-weight');
  font-size: map.get(tokens.$heading-large-text, 'mobile' ,'font-size');

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    font-size: map.get(tokens.$heading-large-text, 'tablet' ,'font-size');
  }

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    font-size: map.get(tokens.$heading-large-text, 'desktop' ,'font-size');
  }
}

.HomepageYouCanDo__imageContainer {
  position: relative;
  height: 320px;
  overflow: hidden;
  border-radius: tokens.$radius-400;

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    flex: 5 1 0;
  }
}

.HomepageYouCanDo__image {
  object-fit: cover;
}