@use 'theme' as theme;

.GraphBar {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  height: theme.$graph-bar-thickness;
  margin-top: theme.$graph-bar-margin-top;
  border-radius: theme.$graph-bar-radius;
  background-color: theme.$graph-bar-background;
}

.GraphBar--vertical {
  width: theme.$graph-bar-thickness;
  height: 100%;
  margin-top: 0;
}

.GraphBar > span {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: theme.$graph-bar-thickness;
  border-radius: theme.$graph-bar-radius;
  background-color: theme.$graph-bar-progress-background;
}

.GraphBar--vertical > span {
  width: theme.$graph-bar-thickness;
  height: 0;
}
