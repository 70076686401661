@use 'sass:map';
@use '../../../styles/@tokens' as tokens;
@use '../../../styles/tokens/colors' as colors;
@use '@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;

.HomepageStatistics {
  overflow: hidden;
  border-radius: tokens.$radius-300;
}

.HomepageStatistics__gradient {
  height: tokens.$space-300;
  background: tokens.$brand-gradient;
}

.HomepageStatistics__content {
  display: flex;
  flex-direction: column;
  padding: tokens.$space-1100 tokens.$space-1300;
  background: colors.$background-warm;


  @include breakpoint.up(tokens.$breakpoint-tablet) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.HomepageStatistics__verticalSeparator {
  width: 1px;
  background-color: colors.$gray-500;
}

.HomepageStatistics__content hr {
  padding-bottom: tokens.$space-700;
}

.HomepageStatistics__stat--title {
  font-weight: map.get(tokens.$heading-large-text, 'desktop', 'font-weight');
  font-size: map.get(tokens.$heading-large-text, 'desktop', 'font-size');
  text-align: center;
}

.HomepageStatistics__stat--subtitle {
  font-size: map.get(tokens.$body-medium-text-regular, 'desktop', 'font-size');
  text-align: center;
}