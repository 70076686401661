@use '../../../../../styles/@tokens' as tokens;

.CustomControl {
  /* stylelint-disable declaration-no-important */
  min-height: 45px !important;
  border-color: tokens.$border-primary-default !important;
  box-shadow: none !important;
  /* stylelint-enable declaration-no-important */
}

.CustomControl--focused {
  /* stylelint-disable-next-line declaration-no-important */
  box-shadow: 0 0 0 2px tokens.$focus-default !important;
}

.CustomControl__icon {
  margin: tokens.$space-400 0 tokens.$space-400 tokens.$space-400;
  line-height: 1;
}

.CustomControlB2bAdmin__icon {
  margin: tokens.$space-400 tokens.$space-400 tokens.$space-400 0;
  line-height: 1;
}

.CustomControlB2bAdmin__icon--open {
  transform: rotate(180deg);
}

.CustomControl__icon path {
  fill: tokens.$text-primary-default;
}

.CustomControlB2bAdmin {
  /* stylelint-disable declaration-no-important */
  min-height: 42px !important;
  border-color: tokens.$border-primary-default !important;
  box-shadow: none !important;
  /* stylelint-enable declaration-no-important */
}
