@use '~@almacareer/atmoskop-design-tokens/index' as tokens;
@use 'styles/tokens/colors' as colors;
@use "sass:map";

.DropdownItem {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: tokens.$space-400 tokens.$space-500;
  overflow: auto;
  font-size: map.get(tokens.$body-small-text-regular, 'mobile', 'font-size');
  line-height: map.get(tokens.$body-small-text-regular, 'mobile', 'line-height');
  text-align: left;
  word-break: break-word;
  border: 0;
  border-radius: tokens.$radius-100;
  background: transparent;
  cursor: pointer;
}

.DropdownItem:disabled {
  cursor: default;
}

.DropdownItem:focus:enabled,
.DropdownItem:hover:enabled,
.DropdownItem--selected {
  background-color: colors.$gray-100;
}

.DropdownItem--selected {
  font-weight: map.get(tokens.$body-small-text-bold, 'mobile', 'font-weight');
}

.DropdownItem:active:enabled {
  background-color: colors.$gray-400;
}

.DropdownItemInGroup {
  width: calc(100% - #{tokens.$space-600});
  margin-left: tokens.$space-600;
}
