@use 'sass:map';
@use 'styles/@tokens' as tokens;

.DiscoverCompaniesDropdown__trigger {
  cursor: pointer;
}

.DiscoverCompaniesDropdown__trigger--open {
  color: tokens.$action-primary-default;
}

.DiscoverCompaniesDropdown__popover {
  z-index: tokens.$zindex-dropdown;
  padding: tokens.$space-600 tokens.$space-400 tokens.$space-700;
}

.DiscoverCompaniesDropdown hr {
  margin-inline: 0;
  margin-block: tokens.$space-100;
  border: 0;
  border-top: tokens.$border-width-100 tokens.$border-style-100 tokens.$border-primary-default;
}

.DiscoverCompaniesDropdown__link:hover {
  text-decoration: none;
}

// Increase specificity in order to override .DropdownItem styles
.DiscoverCompaniesDropdown .DiscoverCompaniesDropdown__item {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: flex-start;
}
