@use 'sass:map';
@use '../../../styles/@tokens' as tokens;
@use '../../../styles/tokens/colors' as colors;
@use '../../../node_modules/@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;

.HomepageCarousel__container {
  margin-inline: - tokens.$space-700;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    margin-inline: auto;
  }
}

.HomepageCarousel__upperTextWrapper {
  padding-inline: tokens.$space-700;
  margin-bottom: tokens.$space-900;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    padding-inline: tokens.$space-500;
    margin: 0 tokens.$space-900 tokens.$space-900;
  }
}

.HomepageCarousel__upperTextTitle {
  font-weight: map.get(tokens.$heading-medium-text, 'tablet', 'font-weight');
  font-size: map.get(tokens.$heading-medium-text, 'tablet', 'font-size');
}

.HomepageCarousel__upperTextParagraph {
  max-width: 670px;
}
