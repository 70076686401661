@use 'sass:map';
@use '../../../styles/@tokens' as tokens;
@use '../../../styles/tokens/colors' as colors;
@use '@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;

$image-is-not-readable-breakpoint: 450px;

.HomepageQuestionnaireSection {
  overflow: hidden;
  border-radius: tokens.$radius-300;
}

.HomepageQuestionnaireSection__gradient {
  height: tokens.$space-300;
  background: tokens.$brand-gradient;
}

.HomepageQuestionnaireSection__content {
  padding: tokens.$space-1300 tokens.$space-700;
  font-weight: map.get(tokens.$body-small-text-regular, 'mobile', 'font-weight');
  font-size: map.get(tokens.$body-small-text-regular, 'mobile', 'font-size');
  line-height: map.get(tokens.$body-small-text-regular, 'mobile', 'line-height');
  text-align: center;
  background: colors.$background-warm;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    padding: tokens.$space-1300 tokens.$space-800;
  }

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    padding: tokens.$space-1300 tokens.$space-1400;
  }
}

.HomepageQuestionnaireSection__content h2 {
  font-weight: map.get(tokens.$heading-large-text, 'mobile', 'font-weight');
  font-size: map.get(tokens.$heading-large-text, 'mobile', 'font-size');
  line-height: map.get(tokens.$heading-large-text, 'mobile', 'line-height');
}

.HomepageQuestionnaireSection__contentSubtitle {
  font-weight: map.get(tokens.$body-medium-text-regular, 'mobile', 'font-weight');
}

.HomepageQuestionnaireSection__imageWrapper {
  padding-top: tokens.$space-900;
  margin-inline: -#{tokens.$space-700};

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    margin-inline: 0;
  }

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    padding-bottom: tokens.$space-900;
  }
}

/* stylelint-disable declaration-no-important */
.HomepageQuestionnaireSection__defaultImage {
  display: none !important;

  @include breakpoint.up($image-is-not-readable-breakpoint) {
    display: block !important;
  }
}

.HomepageQuestionnaireSection__imageWithLargerText {
  display: block !important;

  @include breakpoint.up($image-is-not-readable-breakpoint) {
    display: none !important;
  }
}
/* stylelint-enable declaration-no-important */

.HomepageQuestionnaireSection__image {
  max-width: 100%;
  height: auto;
}

.HomepageQuestionnaireSection__tips {
  display: none;
  text-align: left;

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    display: flex;
    gap: tokens.$space-700;
  }
}

.HomepageQuestionnaireSection__tip {
  display: flex;
}

.HomepageQuestionnaireSection__tipContent {
  padding-left: tokens.$space-500;
}

.HomepageQuestionnaireSection__tipContent strong {
  font-weight: map.get(tokens.$body-medium-text-bold, 'mobile', 'font-weight');
  font-size: map.get(tokens.$body-medium-text-bold, 'mobile', 'font-size');
}