@use 'sass:map';
@use '../../../styles/@tokens' as tokens;

.Graph {
  padding-top: tokens.$space-500;
  padding-bottom: tokens.$space-600;
}

.Graph__description {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.Graph__subLabel {
  display: block;
  font-size: map.get(tokens.$body-medium-text-regular, 'mobile', 'font-size');
}

.Graph__percentage {
  padding-right: tokens.$space-400;
  padding-left: tokens.$space-500;
}
