@use '../../../styles/tokens/colors' as colors;
@use '../../../styles/@tokens' as tokens;

.NextAlert {
  display: flex;
  border-radius: tokens.$radius-300;
}

.NextAlert a {
  text-decoration: underline;
  color: inherit;
}

.NextAlert > div {
  width: 100%;
}

.NextAlert__content {
  flex-grow: 1;
}

.NextAlert__close {
  display: flex;
  justify-content: flex-end;
  margin-left: tokens.$space-300;
  cursor: pointer;
}