@use '../../../styles/@tokens' as tokens;

.Gradient {
  width: 100%;
  height: tokens.$space-300;
  background-image: tokens.$brand-gradient;
}

.Gradient--reverse {
  background-image: tokens.$brand-gradient-reverse;
}
