@use 'sass:map';
@use '../../../styles/@tokens' as tokens;
@use '../../../styles/tokens/colors' as colors;
@use '../../../styles/tools';
@use '@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;

.ProfileCard {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  background: tokens.$background-basic;

  @include tools.card ();
}

.ProfileCard:hover,
.ProfileCard:active,
.ProfileCard:focus-within {
  box-shadow: tokens.$shadow-200;
}

.ProfileCard__photoWrapper {
  position: relative;
  width: 100%;
  aspect-ratio: 22/9;
  overflow: hidden;
}

.ProfileCard__photoWrapper--noImage {
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  aspect-ratio: 22/9;
  padding: tokens.$space-900 0;
  background-color: colors.$gray-300;
}

.ProfileCard__photo {
  object-fit: cover;
}

.ProfileCard__logoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 160px;
  height: 80px;
  margin: tokens.$space-400 auto tokens.$space-500;
}

.ProfileCard__logoWrapper > img {
  max-width: 100%;
  max-height: 100%;
}

.ProfileCard__section {
  padding: tokens.$space-700 tokens.$space-600;
}

.ProfileCard__section:not(:last-child) {
  border-bottom: 1px tokens.$border-secondary-default solid;
}

.ProfileCard__fieldOfBusinessTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ProfileCard__contentHeader {
  min-height: 4.25rem;
  margin-bottom: tokens.$space-600;
  text-align: center;
}

.ProfileCard__name {
  font-weight: map.get(tokens.$heading-xsmall-text, 'mobile', 'font-weight');
  font-size: map.get(tokens.$heading-xsmall-text, 'mobile', 'font-size');
}

a:hover .ProfileCard__name {
  color: tokens.$action-link-primary-default;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
a.ProfileCard__unstyledLink {
  text-decoration: none;
  color: tokens.$text-primary-default;

  &:hover,
  &:focus {
    color: currentcolor;
  }
}

.ProfileCard__section ul {
  list-style: none;
}

.ProfileCard__section ul > li {
  position: relative;
  padding-left: 1.5em;

  &::before {
    content: '';
    position: absolute;
    top: (1 + ((map.get(tokens.$body-small-text-regular, 'mobile', 'line-height') - 1) / 2)) * 0.5em;
    left: 0;
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: tokens.$text-secondary-default;
  }
}

.ProfileCard__section--actionButtons {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: tokens.$space-400;
  justify-content: flex-end;
}

.ProfileCard__section--actionButtons a > button {
  width: 100%;
}