@use '../../styles/@tokens' as tokens;

$hamburger-bars-count: 3;

$hamburger-size: 40px;

$hamburger-bar-width: 22px;
$hamburger-bar-height: 2px;
$hamburger-bar-offset: 6px;
$hamburger-bar-color: tokens.$action-link-secondary-default;
$hamburger-bar-radius: tokens.$radius-100;

$hamburger-bars-wrapper-height: ($hamburger-bars-count * $hamburger-bar-height) +
  (($hamburger-bars-count - 1) * ($hamburger-bar-offset - $hamburger-bar-height));

$hamburger-transition-duration: 0.2s;

$navbar-button-size: 2.5rem;