@use 'sass:map';
@use 'theme' as theme;
@use '../../../styles/@tokens' as tokens;
@use '../../../styles/tokens/colors' as colors;
@use '@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;

.HomepageHero {
  position: relative;
  display: flex;
  align-items: end;
  height: theme.$homepage-hero-container-mobile-height;
  padding-bottom: tokens.$space-1300;
  color: colors.$text-primary-inverted-default;
  background-image: url("./../../../public/images/homepageHero/hero_mobile.jpg");
  background-position-x: center;
  background-position-y: top;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.25;
  }

  @media (min-resolution: 1.5dppx) {
    background-image: url("./../../../public/images/homepageHero/hero_mobile@2x.jpg");
  }

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    align-items: center;
    height: theme.$homepage-hero-container-tablet-height;
    padding-bottom: tokens.$space-0;
    background-image: url("./../../../public/images/homepageHero/hero_tablet.jpg");
    background-position-y: center;

    @media (min-resolution: 1.5dppx) {
      background-image: url("./../../../public/images/homepageHero/hero_tablet@2x.jpg");
    }

    &::before {
      background-color: transparent;
    }
  }

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    background-image: url("./../../../public/images/homepageHero/hero_desktop.jpg");

    @media (min-resolution: 1.5dppx) {
      background-image: url("./../../../public/images/homepageHero/hero_desktop@2x.jpg");
    }
  }
}

.HomepageHero__wrapper {
  position: relative;
  z-index: 1;
  max-width: 100%;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    max-width: theme.$homepage-hero-wrapper-tablet-max-width;
  }
}

.HomepageHero h1 {
  margin-bottom: 0;
  font-weight: map.get(tokens.$heading-xlarge-text, 'mobile', 'font-weight');
  font-size: map.get(tokens.$heading-xlarge-text, 'mobile', 'font-size');
  line-height: map.get(tokens.$heading-xlarge-text, 'mobile', 'line-height');

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    font-size: map.get(tokens.$heading-xlarge-text, 'tablet', 'font-size');
  }
}

.HomepageHero__subtitle {
  padding-top: tokens.$space-600;
  padding-bottom: tokens.$space-900;
  font-weight: map.get(tokens.$body-large-text-regular, 'mobile', 'font-weight');
  font-size: map.get(tokens.$body-large-text-regular, 'mobile', 'font-size');
  line-height: map.get(tokens.$body-large-text-regular, 'mobile', 'line-height');

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    font-size: map.get(tokens.$body-xlarge-text-regular, 'mobile', 'font-size');
  }
}

.HomepageHero__buttons {
  display: flex;
  flex-direction: column;
  gap: tokens.$space-500;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    flex-direction: row;
  }
}

.HomepageHero__button--transparent {
  color: colors.$text-primary-inverted-default;
  border: tokens.$border-width-100 colors.$white tokens.$border-style-100;
  background: transparent;

  &:focus,
  &:hover {
    color: colors.$text-primary-inverted-default;
    border: tokens.$border-width-100 colors.$white tokens.$border-style-100;
    background: transparent;
  }
}
