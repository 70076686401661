@use 'sass:math';
@use 'theme';
@use '../../../styles/@tokens' as tokens;
@use '../../../node_modules/@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;

.Carousel {
  position: relative;
  display: flex;
  width: 100%;
  margin-inline: auto;
}

.Carousel__arrowWrapper {
  flex-shrink: 0;
  align-self: center;
  line-height: 0;
  cursor: pointer;
}

.Carousel__arrowWrapper svg {
  width: theme.$arrow-width;
  height: theme.$arrow-height;
  margin: theme.$arrow-margin;
}

.Carousel__svgWrapper--faded {
  color: theme.$arrow-faded;
  cursor: default;
}

.Carousel__arrowWrapper:first-child {
  rotate: 180deg;
}

.Carousel__inner {
  position: relative;
  display: flex;
  gap: theme.$carousel-gap;
  width: 100%;
  padding-inline: tokens.$space-700;
  overflow: hidden;
  overflow-x: scroll;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    padding-inline: 0;
    margin-inline: theme.$inner-margin-x-tablet;
  }

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    overflow-x: hidden;
  }
}
