@use '../../styles/@tokens' as tokens;
@use '../../styles/tokens/colors' as colors;
@use "../../styles/tools/transitions";
@use 'theme' as theme;

.HamburgerMenu {
  position: relative;
}

.HamburgerMenu__link {
  &:hover {
    text-decoration: none;
  }
}

.HamburgerMenu > div {
  position: absolute;
  top: calc(100% + #{tokens.$space-400});
  right: 0;
}

.HamburgerMenu hr {
  margin: tokens.$space-400 0;
  border: 0;
  border-top: tokens.$border-width-100 tokens.$border-style-100 tokens.$border-primary-default;
}

.HamburgerMenu__hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: theme.$hamburger-size;
  height: theme.$hamburger-size;
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.HamburgerMenu__barsWrapper {
  position: relative;
  width: theme.$hamburger-bar-width;
  height: theme.$hamburger-bars-wrapper-height;
}

.HamburgerMenu__bar {
  position: absolute;
  display: block;
  width: theme.$hamburger-bar-width;
  height: theme.$hamburger-bar-height;
  border-radius: theme.$hamburger-bar-radius;
  background-color: theme.$hamburger-bar-color;

  &:nth-of-type(1) {
    top: 0;
    transition: top theme.$hamburger-transition-duration ease theme.$hamburger-transition-duration,
      transform theme.$hamburger-transition-duration ease-out 0.1s;
  }

  &:nth-of-type(2) {
    top: 1 * theme.$hamburger-bar-offset;
    transition: theme.$hamburger-transition-duration ease theme.$hamburger-transition-duration;
  }

  &:nth-of-type(3) {
    top: 2 * theme.$hamburger-bar-offset;
    transition: top theme.$hamburger-transition-duration ease theme.$hamburger-transition-duration,
      transform theme.$hamburger-transition-duration ease-out 0.1s;
  }
}

.HamburgerMenu__hamburger--open .HamburgerMenu__bar {
  &:nth-of-type(1) {
    top: theme.$hamburger-bar-offset;
    transform: rotate(45deg);
    transition: top theme.$hamburger-transition-duration ease 0.1s,
      transform theme.$hamburger-transition-duration ease-out 0.5s;
  }

  &:nth-of-type(2) {
    opacity: 0;
  }

  &:nth-of-type(3) {
    top: theme.$hamburger-bar-offset;
    transform: rotate(-45deg);
    transition: top theme.$hamburger-transition-duration ease 0.1s,
      transform theme.$hamburger-transition-duration ease-out 0.5s;
  }
}

.HamburgerMenu__itemLink--primary {
  color: tokens.$action-primary-default;
}

.HamburgerMenu__user {
  display: flex;
  align-items: center;
  justify-content: center;
  width: theme.$navbar-button-size;
  height: theme.$navbar-button-size;
  color: tokens.$text-primary-inverted-default;
  border-radius: 50%;
  background-color: colors.$gray-300;
  cursor: pointer;
  @include transitions.transition-hover ();

  &:focus,
  &:hover {
    background-color: colors.$gray-400;
  }
}

.HamburgerMenu__user > svg {
  flex-shrink: 0;
}