@use '../../../styles/@tokens' as tokens;
@use '../Carousel/theme' as theme;
@use '../../../node_modules/@lmc-eu/spirit-web/scss/tools/breakpoint' as breakpoint;

.CarouselItem {
  display: flex;
  flex: 0 0 theme.$carousel-column-width-mobile;
  transition: transform 0.6s ease-in-out;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    flex: 0 0 theme.$carousel-column-width-tablet;
  }

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    flex: 0 0 theme.$carousel-column-width-desktop;
  }
}

.CarouselItem--fullWidth {
  display: flex;
  flex: 0 0 100%;
}
