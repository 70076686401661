@use '../styles/@tokens' as tokens;
@use "../../../node_modules/@lmc-eu/spirit-web/scss/tools/breakpoint";

.CompanySearchResults {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    width: 336px;
  }
}
