@use '../../../../../styles/@tokens' as tokens;

.CustomMultiValueRemove {
  padding: calc(#{tokens.$space-200} + #{tokens.$space-300}) tokens.$space-400 tokens.$space-200;
  margin-left: tokens.$space-100;
  border-radius: 0 tokens.$space-300 tokens.$space-300 0;
  background-color: tokens.$action-selected-default;
  cursor: pointer;

  &:active {
    background-color: tokens.$action-selected-active;
  }

  &:focus,
  &:hover {
    background-color: tokens.$action-selected-hover;
  }
}