@use 'sass:map';
@use '../../../styles/@tokens' as tokens;
@use '../../../styles/tokens/colors' as colors;

.HomepageSuitableCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.HomepageSuitableCard__overlayWrapper {
  position: relative;
  height: 230px;
  cursor: pointer;
}

.HomepageSuitableCard__overlayWrapper::before {
  content:'';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: tokens.$radius-200;
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 28.65%, rgb(0 0 0 / 40%) 60%, rgb(0 0 0 / 80%) 100%);
}

.HomepageSuitableCard__overlayWrapper:hover::before,
.HomepageSuitableCard__overlayWrapper:focus::before {
  background: linear-gradient(180deg, rgb(0 0 0 / 20%) 0%, rgb(0 0 0 / 100%) 100%);
}

.HomepageSuitableCard__image {
  z-index: -1;
  object-fit: cover;
  border-radius: tokens.$radius-200;
}

.HomepageSuitableCard__heading {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: tokens.$space-700 tokens.$space-600;
  font-weight: map.get(tokens.$body-medium-text-bold, 'mobile', 'font-weight');
  font-size: map.get(tokens.$body-medium-text-bold, 'mobile', 'font-size');
  color: colors.$text-primary-inverted-default;
}

.HomepageSuitableCard__body {
  margin: tokens.$space-500 tokens.$space-700 tokens.$space-500 tokens.$space-0;
  font-size: map.get(tokens.$body-small-text-regular, 'mobile', 'font-size');
}
