@use '../../../styles/@tokens' as tokens;
@use '../../../styles/tools';
@use "../../../node_modules/@lmc-eu/spirit-web/scss/tools/breakpoint";

.HomepageInterestingProfiles__profileCardsWithSuitableFor {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: tokens.$space-600;
  overflow-x: scroll;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    grid-template-columns: repeat(3, 1fr);
    overflow-x: initial;
  }

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    gap: tokens.$space-900;
  }
}

.HomepageInterestingProfiles__profileCardWithSuitableFor {
  width: 260px;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    width: auto;
  }

  @include tools.card ();
}

.HomepageInterestingProfiles__profileCardWithSuitableForPlaceholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: tokens.$text-secondary-inverted-default;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    display: none;
  }
}

.HomepageInterestingProfiles__profileCardsMini {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: tokens.$space-600;

  @include breakpoint.up(tokens.$breakpoint-tablet) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include breakpoint.up(tokens.$breakpoint-desktop) {
    gap: tokens.$space-900;
  }
}