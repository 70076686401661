@use '../../../../../styles/@tokens' as tokens;

.CustomInput__multiValueInputContainer {
  display: flex;
  align-items: center;
}

.CustomInput__defaultInputContainer {
  grid-area: 1/1/2/3;
}

.CustomInput__multiValuePlaceholder {
  color: tokens.$action-link-primary-default;
}